<template>
  <div>
    <a-card
      title="商品分类维护"
      class="CategoryMaintenance_wrapper card__wrapper"
    >
      <template #extra>
        <a href="javascript:location.reload();"> 刷新 </a>
      </template>
      <div class="card_content">
        <div class="item_content">
          <div class="btn_box">
            <a-button @click="handleAdd('lData', 'lActive', 1)"
              >新增分类</a-button
            >
            <a-button
              @click="handleUpdate('lcData', 'lcActive', 'lData', 'lActive', 1)"
              :disabled="!lcActive"
              >编辑分类
            </a-button>
            <a-button
              @click="handleDelete('lcActive', 1, 'lActive')"
              :disabled="!lcActive"
              >删除分类</a-button
            >
          </div>
          <div class="category-card">
            <div
              v-for="item in lcData"
              :key="item.categoryId"
              :class="{
                'category-item': true,
                active: item.categoryId === lcActive,
              }"
              @click="handleActive(item.categoryId, 'lcActive', 2)"
            >
              <div class="text">{{ item.name }}</div>
              <div class="icon">
                <ArrowRightOutlined />
              </div>
            </div>
          </div>
        </div>
        <div class="item_content">
          <div class="btn_box">
            <a-button
              @click="handleAdd('lcData', 'lcActive', 2)"
              :disabled="!lcActive"
              >新增分类</a-button
            >
            <a-button
              @click="handleUpdate('cData', 'cActive', 'lcData', 'lcActive', 2)"
              :disabled="!cActive"
              >编辑分类
            </a-button>
            <a-button
              @click="handleDelete('cActive', 2, 'lcActive')"
              :disabled="!cActive"
              >删除分类</a-button
            >
          </div>
          <div class="category-card">
            <div
              v-for="item in cData"
              :key="item.categoryId"
              :class="{
                'category-item': true,
                active: item.categoryId === cActive,
              }"
              @click="handleActive(item.categoryId, 'cActive', 3)"
            >
              <div class="text">{{ item.name }}</div>
              <div class="icon">
                <ArrowRightOutlined />
              </div>
            </div>
          </div>
        </div>
        <div class="item_content">
          <div class="btn_box">
            <a-button
              @click="handleAdd('cData', 'cActive', 3)"
              :disabled="!cActive"
              >新增分类</a-button
            >
            <a-button
              @click="handleUpdate('crData', 'crActive', 'cData', 'cActive', 3)"
              :disabled="!crActive"
              >编辑分类
            </a-button>
            <a-button
              @click="handleDelete('crActive', 3, 'cActive')"
              :disabled="!crActive"
              >删除分类</a-button
            >
          </div>
          <div class="category-card">
            <div
              v-for="item in crData"
              :key="item.categoryId"
              :class="{
                'category-item': true,
                active: item.categoryId === crActive,
              }"
              @click="handleActive(item.categoryId, 'crActive')"
            >
              <div class="text">{{ item.name }}</div>
              <div class="icon"></div>
            </div>
          </div>
        </div>
      </div>
      <a-modal
        width="800px"
        :title="title"
        :visible="visible"
        @ok="handleOk"
        @cancel="handleCancel"
        :confirmLoading="confirmLoading"
      >
        <a-form
          :model="form"
          ref="form"
          style="height: 500px; overflow: auto"
          :rules="rules"
          :wrapper-col="{ span: 16 }"
          :label-col="{ span: 4 }"
        >
          <a-form-item
            prop="parentName"
            label="父级分类名称"
            v-if="visibleDisabled"
          >
            <a-input
              v-model:value="form.parentName"
              allowClear
              :disabled="true"
            ></a-input>
          </a-form-item>
          <a-form-item prop="name" label="分类名称">
            <a-input v-model:value="form.name" allowClear></a-input>
          </a-form-item>
          <a-form-item prop="path" label="上传图片" v-if="showUpload">
            <upload
              v-if="visible"
              ref="upload"
              @handleChange="handleChangeUpload"
              :FormData="form"
              :listType="'picture-card'"
              :typeNumber="3"
              :fileNumber="1"
            ></upload>
          </a-form-item>
          <a-form-item
            prop="categoryAttributeValues"
            label="商品属性绑定"
            v-if="showUpload"
          >
            <dictData
              v-if="visible"
              v-model:dictValue="form.categoryAttributeValues"
              :source="2"
            ></dictData>
          </a-form-item>
        </a-form>
      </a-modal>
    </a-card>
  </div>
</template>

<script>
import {
  getCommodityList,
  addCommodity,
  updateCommodity,
  deleteCommodity,
} from "@/api/commodityManagement/commodityType";
import { ArrowRightOutlined } from "@ant-design/icons-vue";
import dictData from "@/components/dictData";
import upload from "@/components/upload";
const form = {
  type: 2,
  parentName: "",
  name: "",
  path: "",
  fileName: "",
  categoryAttributeValues: [{ attributeId: undefined, children: [] }],
};
export default {
  name: "CategoryMaintenance",
  components: {
    ArrowRightOutlined,
    upload,
    dictData,
  },
  data() {
    return {
      showUpload: false,
      visibleDisabled: false,
      lActive: null,
      lcActive: null,
      cActive: null,
      crActive: null,
      rActive: null,
      lData: [],
      lcData: [],
      cData: [],
      crData: [],
      rData: [],
      edit: false,
      visible: false,
      editLevel: null,
      pid: null,
      form: {
        ...form,
      },
      rules: {
        name: [
          {
            required: true,
            trigger: "blur",
            message: "请输入分类名称",
          },
        ],
        path: [
          {
            required: true,
            trigger: "blur",
            message: "请上传图片",
          },
        ],
      },
      confirmLoading: false,
    };
  },
  computed: {
    title() {
      return this.edit ? "编辑商品分类分类" : "新增商品分类分类";
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.handleGetData();
    },
    handleGetData(level = 1, pid = null) {
      this[`loading${level}`] = true;
      const l = level === 1 ? level : null;
      getCommodityList({
        type: 2,
        level: l,
        pid: pid,
      })
        .then((res) => {
          if (level === 1) {
            this.lcActive = (
              res.data.find((item) => item.categoryId === this.lcActive) || {}
            ).categoryId;
            this.lcData = res.data;
          } else if (level === 2) {
            this.cActive = (
              res.data.find((item) => item.categoryId === this.cActive) || {}
            ).categoryId;
            this.cData = res.data;
          } else if (level === 3) {
            this.crActive = (
              res.data.find((item) => item.categoryId === this.crActive) || {}
            ).categoryId;
            this.crData = res.data;
          }
        })
        .finally(() => [(this[`loading${level}`] = false)]);
    },
    handleActive(id, key, level) {
      if (this[key] === id) {
        return;
      }
      this[key] = id;
      if (!level) {
        return;
      }
      if (key === "lActive") {
        this.lcData = [];
        this.lcActive = null;
        this.cData = [];
        this.cActive = null;
        this.crData = [];
        this.crActive = null;
        this.rData = [];
        this.rActive = null;
      }
      if (key === "lcActive") {
        this.cData = [];
        this.cActive = null;
        this.crData = [];
        this.crActive = null;
        this.rData = [];
        this.rActive = null;
      }
      if (key === "cActive") {
        this.crData = [];
        this.crActive = null;
        this.rData = [];
        this.rActive = null;
      }
      if (key === "crActive") {
        this.rData = [];
        this.rActive = null;
      }
      this.handleGetData(level, id);
    },
    handleAdd(data, pid, level) {
      if (level == 1) {
        this.visibleDisabled = false;
        this.showUpload = false;
      } else if (level == 3) {
        this.visibleDisabled = true;
        this.showUpload = true;
      } else {
        this.visibleDisabled = true;
        this.showUpload = false;
      }
      this.editLevel = level;
      this.pid = this[pid];
      this.form = {
        ...form,
        pid: this[pid],
        parentName: this[data].find((item) => item.categoryId === this[pid])
          ?.name,
        level: level,
        type: 2,
        name: "",
        img: "",
      };
      this.visible = true;
    },
    handleUpdate(type, id, parentType, pid, level) {
      if (level == 1) {
        this.visibleDisabled = false;
        this.showUpload = false;
      } else if (level == 3) {
        this.visibleDisabled = true;
        this.showUpload = true;
      } else {
        this.visibleDisabled = true;
        this.showUpload = false;
      }
      this.editLevel = level;
      this.pid = this[pid];
      this.edit = true;
      const rowItem = this[type].find((item) => item.categoryId === this[id]);
      if (level != 1) {
        const parentName = this[parentType].find(
          (item) => item.categoryId === rowItem.pid
        ).name;
        this.form = { ...form, ...rowItem, parentName, type: 2 };
      } else {
        this.form = { ...form, ...rowItem, type: 2 };
      }
      this.visible = true;
    },
    handleDelete(type, level, parentType) {
      this.$confirm({
        title: "商品分类删除提示",
        content: "此分类下其他分类也将一并删除，确定删除此条分类嘛？",
        onOk: () => {
          deleteCommodity({ id: this[type] }).then((res) => {
            if (!res.errMessage) {
              this.$message.success("删除成功");
            }
            this[type] = null;
            if (type === "lActive") {
              this.lcData = [];
              this.lcActive = null;
              this.cData = [];
              this.cActive = null;
              this.crData = [];
              this.crActive = null;
              this.rData = [];
              this.rActive = null;
            }
            if (type === "lcActive") {
              this.cData = [];
              this.cActive = null;
              this.crData = [];
              this.crActive = null;
              this.rData = [];
              this.rActive = null;
            }
            if (type === "cActive") {
              this.crData = [];
              this.crActive = null;
              this.rData = [];
              this.rActive = null;
            }
            if (type === "crActive") {
              this.rData = [];
              this.rActive = null;
            }
            this.handleGetData(level, this[parentType]);
          });
        },
      });
    },
    handleOk() {
      this.$refs.form.validate().then(() => {
        const fn = this.edit ? updateCommodity : addCommodity;
        this.confirmLoading = true;
        fn(this.form)
          .then((res) => {
            if (res.errCode != 500) {
              this.$message.success(`${this.title}成功`);
              this.handleCancel();
              this.handleGetData(this.editLevel, this.pid);
            } else {
              this.$message.error(res.errMessage);
            }
          })
          .finally(() => {
            this.confirmLoading = false;
          });
      });
    },
    handleCancel() {
      this.edit = false;
      this.visible = false;
      this.$refs.form.resetFields();
    },
    //上传文件回调
    handleChangeUpload(info) {
      this.form.path = info.response?.data.fullPath;
      this.form.fileName = info.response?.data.name;
    },
  },
};
</script>

<style lang="less">
.CategoryMaintenance_wrapper {
  .card_content {
    display: flex;
    height: 100%;

    .item_content {
      flex: 1;
      padding-right: 24px;

      &:last-child {
        padding-right: 0px;
      }

      .btn_box {
        height: 32px;
      }
    }
  }

  .category-card {
    border: 1px solid #f0f2f5;
    margin-top: 30px;
    height: calc(100% - 60px);
    overflow: hidden auto;

    .category-item {
      height: 50px;
      border-bottom: 1px solid #eeeeee;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      cursor: pointer;

      &.active {
        background: skyblue;
      }
    }
  }
}
</style>
